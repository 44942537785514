.blackButton{
    display: flex;
    align-items: center;
    gap: 25px;
    color: var(--white);
    border-radius: 5px;
    border: none;
    background: var(--dark);
    text-align: center;
    font-size: 1.25rem; /* 20px */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding: 18px 5vw;
    overflow: hidden;
    flex-direction: column;
    position: relative;
}

@media (max-width: 768px) {
    .blackButton{
        justify-content: center;
        padding: 0 20px;
        min-height: 50px;
    }
    .blackButton p, .blackButton span{
        font-size: 1.125rem;
    }
}

.blackButton svg{
    -webkit-transition: transform 300ms ease-in-out;
    -moz-transition: transform 300ms ease-in-out;
    -o-transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
}
.blackButton:hover svg{
    transform: translateX(10px);
}
.blackButton:hover {
    cursor: pointer;
}
.blackButton .buttonText{
    z-index: 1;
    display: flex;
    gap: 15px;
    align-items: center;
}
.blackButton .gradient{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-transition: opacity 300ms ease-in-out;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    background: var(--hower-dark);
}
.blackButton:hover .gradient{
    opacity: 1;
}
.link{
    text-decoration: none !important;
}
