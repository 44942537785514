.containerDesktop{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerMobile{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
